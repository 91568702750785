import { register, isPlatformAuthenticatorAvailable } from '../webauthn/registration'
import { authenticate } from '../webauthn/authentication'
import intlTelInput from 'intl-tel-input';

function bindEvents() {
  const registerButton = document.getElementById("register")
  const registrationForm = document.getElementById("registration_form")
  const preflightAlert = document.getElementById("preflight")
  const registrationStatus = document.getElementById("registration")
  const tryExperienceRegistrationForm = document.getElementById("try_registration_form")

  registerButton?.addEventListener("click", (register))

  if (registrationForm) {
    const telephoneField = document.getElementById("unverified_registration_mobile_phone_number")
    const itlTelephoneField = intlTelInput(telephoneField, {
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.3/build/js/utils.js",
      customContainer: 'input-group'
    })

    registrationForm.addEventListener("ajax:before", (event) => {
      telephoneField.value = itlTelephoneField.getNumber(intlTelInputUtils.numberFormat.E164)
    })
  }

  if (registrationStatus) {
    setTimeout(() => {
      document.querySelectorAll('div.spinner-border').forEach((elem) => {
        elem.parentNode.innerText = "Timed Out"
      })
    }, 15 * 1000)
  }

  if (preflightAlert) {
    PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
      .then((available) => {
        if (available) {
          preflightAlert.style.display = "none";
        }
      }).catch((err) => {
        alert(err)
      })
  }

  const loginButton = document.getElementById("login")
  loginButton?.addEventListener("click", () => {
    authenticate()
  })

  if (tryExperienceRegistrationForm) {
    tryExperienceRegistrationForm.addEventListener('ajax:success', (event) => {
      const [data, status, _xhr] = event.detail
      alert(`A verification link has been sent to ${data.registration.email_address}`)
    })
  }
}

document.addEventListener("turbolinks:load", bindEvents)