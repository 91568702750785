import * as WebAuthnJSON from "@github/webauthn-json"
import { post, url } from './ajax_helper'

export async function register() {
  const config = await create()
  const authenticator = {
    name: `Authenticator Created ${new Date().toUTCString()}`

  }
  WebAuthnJSON.create({ "publicKey": config.options })
    .then((credential) => callback(authenticator, credential, config.callback_url))
}

async function create() {
  const response = await fetch(url('user/authenticators'), post())

  if (response.ok) {
    return await response.json()
  }

  return null
}

async function callback(authenticator, credential, callback_url) {
  const request = { authenticator: authenticator, credential: credential }
  const response = await fetch(callback_url, post(request))

  if (response.ok) {
    successCallback(await response.json())
  } else {
    failureCallback()
  }
}

function successCallback(credential) {
  localStorage.setItem('credentialId', credential.credential_id)
  alert('FIDO authenticator was successfully registered')

  if (window.location.pathname != "/try") {
    window.location.href = "/"
  }
}

function failureCallback() {
  alert('Registration Failed')
}