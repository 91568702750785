import Trusona from "trusona-trucode"
import BouncingDots from '../trucode/bouncing_dots'
import lottieWeb from 'lottie-web'

const lottieUrl = 'https://static.trusona.net/trugateway/js/lottie-a78d14f0cc985a5def090bbe0605b93c.json'
const bouncingDots = new BouncingDots(lottieUrl, lottieWeb)

export function generate(data) {
  const trucode = document.getElementById("trucode")
  const codeCanvas = document.getElementById("code-canvas")

  if (trucode == null || codeCanvas == null) return

  const config = {
    "dotColor": "#7B46D1",
    "shapeColors": ["#7B46D1", "#7B46D1", "#7B46D1", "#5856c2", "#4a4cc2", "#5a57b0", "#4d4a97"],
    "animationConfig": {
      "repeatDelay": 7200
    }
  }

  Trusona._drawTruCode(codeCanvas, data.url, config)
  trucode.style.visibility = "visible";

  loadAnimations()
}

export function hide() {
  const codeCanvas = document.getElementById("code-canvas")
  const codeCaption = document.getElementById("code-caption")
  codeCanvas.style.visibility = "hidden"
  bouncingDots.playScannedAndWaitingLoop()
  codeCaption.innerHTML = '<p class="text-center">Accept in your mobile browser</p>'
}

function loadAnimations() {
  const codeOverlay = document.getElementById("code-overlay")
  bouncingDots.render(codeOverlay)
}