class BouncingDots {
  constructor(lottiePath, lottie) {
    this.lottiePath = lottiePath
    this.lottie = lottie
    this.segments = {
      scanned: [1, 72],
      waiting: [72, 220]
    }
  }

  render(container) {
    this.animation = this.lottie.loadAnimation({
      container: container,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: this.lottiePath
    })
  }

  playScannedAndWaitingLoop() {
    this.playScanned().then(this.playWaitingLoop.bind(this))
  }

  playWaitingLoop() {
    this.playWaiting().then(this.playWaitingLoop.bind(this))
  }

  playScanned() {
    return this._playSegment(this.segments.scanned)
  }

  playWaiting() {
    return this._playSegment(this.segments.waiting)
  }

  _startSegment() {
    if (this.currentSegment) {
      throw Error('Cannot start the next segment while the current one is playing')
    }
    return new Promise((resolve) => {
      this.currentSegment = resolve
    })
  }

  _endSegment() {
    const callback = this.currentSegment
    this.currentSegment = undefined
    callback()
  }

  _playSegment(segment, event = 'complete', callback = this._handleSegmentComplete.bind(this), force = true) {
    const promise = this._startSegment()
    this.animation.goToAndStop(segment[0], true)
    this.animation.addEventListener(event, callback)
    this.animation.playSegments(segment, force)
    return promise
  }

  _handleSegmentComplete() {
    this.animation.removeEventListener('complete')
    this._endSegment()
  }
}

export default BouncingDots