import consumer from './consumer'
import * as Trucode from '../trucode/generator'
import * as Authentication from '../webauthn/authentication'

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('authentication')) {
    consumer.subscriptions.create({ channel: 'AuthenticatorChannel', org_id: document.querySelector('html').dataset.orgId }, {
      connected() {
        this.handshake()
      },

      disconnected() {
      },

      handshake() {
        const remote_id = new URLSearchParams(document.location.search).get('cid')
        const credentialId = localStorage.getItem('credentialId')

        this.perform('handshake', { remote_id: remote_id, credential_id: credentialId })
        if (remote_id == null) this.displayModal()
      },

      displayModal(data = null) {
        const accept = document.getElementById('accept')
        const statusText = document.getElementById('status')

        if (accept == null) return;

        accept.addEventListener('click', (event) => {
          var button = event.target

          if(button.tagName != 'BUTTON') {
            button = event.target.parentNode
          }

          button.disabled = true

          if (data == null) {
            Authentication.authenticate()
          } else {
            Authentication.authenticateWithConfig(data, (credential) => {
              this.perform('callback', { credential: credential, callback_url: data.callback_url })
            })
          }
        })

        statusText.textContent = 'Continue'
        accept.style.visibility = 'visible'
      },

      received(message) {
        switch (message.action) {
          case 'hello':
            Trucode.generate(message.data)
            console.log(message.data.url)
            break
          case 'connect':
            Trucode.hide()
            Authentication.create(
              message.data['credential_id']).then((config) => {
                this.perform('authenticate', config)
              })
            break
          case 'authenticate':
            this.displayModal(message.data)
            break
          case 'callback':
            Authentication.callback(message.data['credential'], message.data['callback_url'])
            break
          default:
            console.log('Received unknown action: ' + message.action)
        }
      }
    })

  }
})
