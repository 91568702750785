import * as WebAuthnJSON from "@github/webauthn-json"
import { post, url } from './ajax_helper'

export async function authenticate(credentialId) {
  if (credentialId == null) {
    credentialId = localStorage.getItem('credentialId')
  }

  const config = await create(credentialId)
  authenticateWithConfig(config,
    (credential) => callback(credential, config.callback_url)
  )
}

export function authenticateWithConfig(config, onCredential) {
  WebAuthnJSON.get({ "publicKey": config.options })
    .then((credential) => onCredential(credential))
}

export async function create(credentialId) {
  const response = await fetch(url('authentications'), post({ credential_id: credentialId }))

  if (response.ok) {
    return await response.json()
  }

  return null
}

export async function callback(credential, callback_url) {
  const request = { credential: credential }
  const response = await fetch(callback_url, post(request))

  if (response.ok) {
    successCallback(response)
  } else {
    failureCallback()
  }
}

function successCallback(response) {
  const redirectUri = response.headers.get('location')
  if (redirectUri != null) {
    window.location.href = redirectUri
  } else {
    alert('Successfully Authenticated')
  }
}

function failureCallback() {
  alert('Authentication Failed')
}
