import consumer from "./consumer"

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('registration')) {
    consumer.subscriptions.create("RegistrationChannel", {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        Turbolinks.visit(window.location.toString())
      }
    })
  }
})
